// src/services/api.js

import axios from 'axios';

console.log('api.js - Environment:', process.env.NODE_ENV);
console.log('api.js - REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

const API_BASE_URL = process.env.REACT_APP_API_URL;

console.log('api.js - Final API_BASE_URL:', API_BASE_URL);

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchEvents = async (year, teamNumber) => {
  try {
    console.log(`api.js - Fetching events for year ${year} and team ${teamNumber}`);
    console.log(`api.js - Full URL: ${API_BASE_URL}/api/events/${year}/${teamNumber}`);
    const response = await api.get(`/api/events/${year}/${teamNumber}`);
    return response.data;
  } catch (error) {
    console.error('api.js - Error fetching events:', error);
    throw error;
  }
};

export default api;