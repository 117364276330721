import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  Rating,
  Slider
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ATTEMPTED_OPTIONS = ["Succeeded", "Attempted but Failed", "Didn't Attempt"];
const ATTEMPTED_COLORS = ["success", "error", "warning"];

export default function ScoutingPage() {
  const { eventKey, teamKey, matchKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { alliance: initialAlliance, matchData: initialMatchData } = location.state || {};

  const [scoutingConfig, setScoutingConfig] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [teamData, setTeamData] = useState(null);
  const [matchData, setMatchData] = useState(initialMatchData);
  const [alliance, setAlliance] = useState(initialAlliance);

  useEffect(() => {
    const getAlliance = (match) => {
      if (!match) return { color: 'Unknown', position: 'Unknown' };
      const redAlliance = match.alliances.red.team_keys;
      const blueAlliance = match.alliances.blue.team_keys;
      if (redAlliance.includes(teamKey)) {
        return { color: 'Red', position: redAlliance.indexOf(teamKey) + 1 };
      } else if (blueAlliance.includes(teamKey)) {
        return { color: 'Blue', position: blueAlliance.indexOf(teamKey) + 1 };
      }
      return { color: 'Unknown', position: 'Unknown' };
    };
  
    const fetchData = async () => {
      try {
        setLoading(true);
        const year = eventKey.substring(0, 4);
        const [configResponse, teamResponse, matchResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/scouting-config/${year}`),
          axios.get(`${API_BASE_URL}/api/teams/${teamKey.slice(3)}`),
          matchData ? Promise.resolve({ data: matchData }) : axios.get(`${API_BASE_URL}/api/matches/${matchKey}`)
        ]);
        
        setScoutingConfig(configResponse.data.sections);
        setTeamData(teamResponse.data);
        
        const matchInfo = matchResponse.data;
        setMatchData(matchInfo);

        if (!alliance) {
          setAlliance(getAlliance(matchInfo));
        }

        initializeFormData(configResponse.data.sections);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load necessary data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventKey, teamKey, matchKey, matchData, alliance]);

  const initializeFormData = (sections) => {
    const initialData = {};
    sections.forEach(section => {
      section.metrics.forEach(metric => {
        initialData[metric.name] = 
          metric.type === 'counter' ? { made: 0, missed: 0 } : 
          metric.type === 'boolean' ? false :
          metric.type === 'ranking' ? 0 :
          metric.type === 'range' ? [metric.min, metric.max] :
          metric.type === 'multipleChoice' ? 0 :
          metric.type === 'attempted' ? 0 : '';
      });
    });
    setFormData(initialData);
  };

  const handleInputChange = (metricName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [metricName]: value
    }));
  };

  const handleCounterChange = (metricName, type) => {
    setFormData(prevData => ({
      ...prevData,
      [metricName]: {
        ...prevData[metricName],
        [type]: (prevData[metricName]?.[type] || 0) + 1
      }
    }));
  };

  const handleMultipleChoiceChange = (metricName, options) => {
    setFormData(prevData => ({
      ...prevData,
      [metricName]: (prevData[metricName] + 1) % options.length
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/scouting-data`, {
        team_key: teamKey,
        event_key: eventKey,
        match_key: matchKey,
        data: formData  
      });
      console.log('Scouting data submitted successfully:', response.data);
      setSnackbar({ open: true, message: 'Scouting data submitted successfully', severity: 'success' });

      goToNextMatch();
    } catch (error) {
      console.error('Error submitting scouting data:', error.response ? error.response.data : error.message);
      setSnackbar({ open: true, message: 'Error submitting scouting data', severity: 'error' });
    }
  };

  const goToNextMatch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tba/next-match`, {
        params: {
          eventKey,
          currentMatchKey: matchKey,
          alliance: alliance.color.toLowerCase(),
          position: alliance.position
        }
      });
      
      const nextMatch = response.data;
      if (nextMatch) {
        const nextTeamKey = nextMatch.alliances[alliance.color.toLowerCase()].team_keys[alliance.position - 1];
        navigate(`/scout/${eventKey}/${nextTeamKey}/${nextMatch.key}`, {
          state: { 
            matchData: nextMatch,
            alliance
          }
        });
      } else {
        setSnackbar({ open: true, message: 'No more matches for this team in this position', severity: 'info' });
      }
    } catch (error) {
      console.error('Error fetching next match:', error);
      setSnackbar({ open: true, message: 'Error fetching next match', severity: 'error' });
    }
  };

  const renderMetric = (metric) => {
    switch (metric.type) {
      case 'number':
        return (
          <TextField
            label={metric.name}
            type="number"
            value={formData[metric.name]}
            onChange={(e) => handleInputChange(metric.name, e.target.value)}
            fullWidth
            margin="normal"
          />
        );
      case 'counter':
        return (
          <Box>
            <Typography variant="subtitle1">{metric.name}</Typography>
            <Box display="flex" justifyContent="flex-start" gap={2}>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleCounterChange(metric.name, 'made')}
              >
                Made: {formData[metric.name]?.made || 0}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleCounterChange(metric.name, 'missed')}
              >
                Missed: {formData[metric.name]?.missed || 0}
              </Button>
            </Box>
          </Box>
        );
      case 'boolean':
        return (
          <Button
            variant="contained"
            color={formData[metric.name] ? "success" : "error"}
            onClick={() => handleInputChange(metric.name, !formData[metric.name])}
            startIcon={formData[metric.name] ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}
            fullWidth
          >
            {metric.name}: {formData[metric.name] ? "Yes" : "No"}
          </Button>
        );
      case 'text':
        return (
          <TextField
            label={metric.name}
            value={formData[metric.name]}
            onChange={(e) => handleInputChange(metric.name, e.target.value)}
            fullWidth
            margin="normal"
          />
        );
      case 'ranking':
        return (
          <Box>
            <Typography component="legend">{metric.name}</Typography>
            <Rating
              name={metric.name}
              value={Number(formData[metric.name])}
              onChange={(event, newValue) => {
                handleInputChange(metric.name, newValue);
              }}
            />
          </Box>
        );
      case 'range':
        return (
          <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
            <Typography gutterBottom>{metric.name}</Typography>
            <Slider
              value={formData[metric.name]}
              onChange={(_, values) => handleInputChange(metric.name, values)}
              valueLabelDisplay="auto"
              min={metric.min}
              max={metric.max}
              marks={[
                { value: metric.min, label: metric.min },
                { value: metric.max, label: metric.max }
              ]}
            />
            <Typography variant="caption">
              Selected range: {formData[metric.name] ? `${formData[metric.name][0]} - ${formData[metric.name][1]}` : `${metric.min} - ${metric.max}`}
            </Typography>
          </Box>
        );
      case 'multipleChoice':
        return (
          <Box>
            <Typography variant="subtitle1">{metric.name}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleMultipleChoiceChange(metric.name, metric.options)}
              fullWidth
            >
              {metric.options[formData[metric.name]]}
            </Button>
          </Box>
        );
      case 'attempted':
        return (
          <Box>
            <Typography variant="subtitle1">{metric.name}</Typography>
            <Button
              variant="contained"
              color={ATTEMPTED_COLORS[formData[metric.name]]}
              onClick={() => handleMultipleChoiceChange(metric.name, ATTEMPTED_OPTIONS)}
              fullWidth
            >
              {ATTEMPTED_OPTIONS[formData[metric.name]]}
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const titleColor = alliance.color === 'Red' ? '#D32F2F' : alliance.color === 'Blue' ? '#1976D2' : 'inherit';

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ color: titleColor }}>
        Scout Team {teamKey.slice(3)} - {teamData?.nickname || 'Unknown Team'}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: titleColor }}>
        Alliance: {alliance.color} | Pos: {alliance.position}
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ color: titleColor }}>
        Match {matchKey.split('_')[1]}
      </Typography>
      <form onSubmit={handleSubmit}>
        {scoutingConfig && scoutingConfig.map((section, index) => (
          <Box key={index} mb={3}>
            <Typography variant="h6">{section.name}</Typography>
            {section.metrics.map((metric, metricIndex) => (
              <Box key={metricIndex} mb={2}>
                {renderMetric(metric)}
              </Box>
            ))}
          </Box>
        ))}
        <Button type="submit" variant="contained" color="primary">
          Submit and Next Match
        </Button>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}