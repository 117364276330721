import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme, AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import EventList from './components/EventList';
import EventDetails from './components/EventDetails';
import ScoutingConfigPage from './components/ScoutingConfigPage';
import ScoutingPage from './components/ScoutingPage'; // Make sure this path is correct

// Create a dark theme instance.
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              RoboRecon2 presented by Team Paradox
            </Typography>
            <Button color="inherit" component={Link} to="/">Events</Button>
            <Button color="inherit" component={Link} to="/scouting-config">Scouting Config</Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg">
          <Box mt={3}>
            <Routes>
              <Route path="/" element={<EventList />} />
              <Route path="/event/:eventKey" element={<EventDetails />} />
              <Route path="/scouting-config" element={<ScoutingConfigPage />} />
              <Route path="/scout/:eventKey/:teamKey/:matchKey" element={<ScoutingPage />} />
            </Routes>
          </Box>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;