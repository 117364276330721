
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  CircularProgress, 
  Alert, 
  List, 
  ListItem, 
  ListItemText, 
  Button,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { fetchEvents } from '../services/api';

console.log('EventList.js - Environment:', process.env.NODE_ENV);
console.log('EventList.js - REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

function EventList() {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [teamNumber, setTeamNumber] = useState('2102');  // Set default to '2102'
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({length: currentYear - 1991}, (_, i) => currentYear - i);
    setYears(yearList);
    setSelectedYear(currentYear.toString());

    // Log environment variables on component mount
    console.log('EventList.js - Component mounted. Environment variables:');
    console.log('EventList.js - REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
    console.log('EventList.js - window._env_:', window._env_);
  }, []);

  const handleFetchEvents = async () => {
    if (!teamNumber || !selectedYear) return;
    
    try {
      setLoading(true);
      setError(null);
      console.log(`EventList.js - Fetching events for team ${teamNumber} in year ${selectedYear}`);
      const data = await fetchEvents(selectedYear, teamNumber);
      console.log('EventList.js - Events response:', data);
      setEvents(data);
    } catch (err) {
      console.error('EventList.js - Error fetching events:', err);
      setError(`Failed to fetch events. Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEventClick = (event) => {
    console.log(`EventList.js - Navigating to event: ${event.key}`);
    navigate(`/event/${event.key}`);
  };

  return (
    <div>
      <Typography variant="h4">Events</Typography>
      <TextField
        label="Team Number"
        value={teamNumber}
        onChange={(e) => setTeamNumber(e.target.value.replace(/\D/g, '').slice(0, 4))}
        margin="normal"
        fullWidth
      />
      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        fullWidth
        sx={{ my: 2 }}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year.toString()}>{year}</MenuItem>
        ))}
      </Select>
      <Button onClick={handleFetchEvents} variant="contained" color="primary" fullWidth>
        Fetch Events
      </Button>
      
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      
      {events.length > 0 && (
        <List>
          {events.map((event) => (
            <ListItem key={event.key} component="div" onClick={() => handleEventClick(event)}>
              <ListItemText
                primary={event.name}
                secondary={`${new Date(event.start_date).toLocaleDateString()} - ${new Date(event.end_date).toLocaleDateString()}`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

export default EventList;