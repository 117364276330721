import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
  CircularProgress,
  Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ScoutingFormPreview from './ScoutingFormPreview';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const metricTypes = [
  { value: 'number', label: 'Number' },
  { value: 'counter', label: 'Made/Missed Counter' },
  { value: 'ranking', label: 'Ranking (1-5)' },
  { value: 'boolean', label: 'Yes/No' },
  { value: 'text', label: 'Text Field' },
  { value: 'range', label: 'Range' },
  { value: 'multipleChoice', label: 'Multiple Choice' },
  { value: 'attempted', label: 'Attempted' },
];

const sectionNames = ['Pre-Match', 'Autonomous', 'Teleop', 'Endgame', 'Post-Match'];

const ATTEMPTED_OPTIONS = ["Succeeded", "Attempted but Failed", "Didn't Attempt"];

export default function ScoutingConfigPage() {
  const [year, setYear] = useState('');
  const [sections, setSections] = useState(null);
  const [newMetricName, setNewMetricName] = useState('');
  const [newMetricType, setNewMetricType] = useState('number');
  const [newMetricMin, setNewMetricMin] = useState(0);
  const [newMetricMax, setNewMetricMax] = useState(100);
  const [newMetricOptions, setNewMetricOptions] = useState([]);
  const [newOptionInput, setNewOptionInput] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [availableYears, setAvailableYears] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 5}, (_, i) => currentYear - i);
    setAvailableYears(years);
    setYear(currentYear.toString());
  }, []);

  useEffect(() => {
    if (year) {
      loadConfig(year);
    }
  }, [year]);

  const loadConfig = async (selectedYear) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/scouting-config/${selectedYear}`);
      console.log('Loaded configuration:', response.data);
      if (response.data.sections && Array.isArray(response.data.sections)) {
        setSections(response.data.sections);
      } else {
        console.warn('Invalid sections data received:', response.data.sections);
        setSections(sectionNames.map(name => ({ name, metrics: [] })));
      }
      setSnackbar({ open: true, message: `Configuration for ${selectedYear} loaded`, severity: 'info' });
    } catch (error) {
      console.error('Error loading configuration:', error);
      setSections(sectionNames.map(name => ({ name, metrics: [] })));
      if (error.response && error.response.status === 404) {
        setSnackbar({ open: true, message: `No existing configuration for ${selectedYear}`, severity: 'info' });
      } else {
        setSnackbar({ open: true, message: 'Error loading configuration', severity: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddMetric = () => {
    if (newMetricName.trim() !== '' && sections) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        const newMetric = { 
          name: newMetricName, 
          type: newMetricType,
          ...(newMetricType === 'range' && { min: newMetricMin, max: newMetricMax }),
          ...(newMetricType === 'multipleChoice' && { options: newMetricOptions }),
          ...(newMetricType === 'attempted' && { options: ATTEMPTED_OPTIONS })
        };
        newSections[currentSection].metrics.push(newMetric);
        return newSections;
      });
      setNewMetricName('');
      setNewMetricType('number');
      setNewMetricMin(0);
      setNewMetricMax(100);
      setNewMetricOptions([]);
    }
  };

  const handleRemoveMetric = (metricIndex) => {
    if (sections) {
      setSections(prevSections => {
        const newSections = [...prevSections];
        newSections[currentSection].metrics.splice(metricIndex, 1);
        return newSections;
      });
    }
  };

  const handleAddOption = () => {
    if (newOptionInput.trim() !== '') {
      setNewMetricOptions(prev => [...prev, newOptionInput.trim()]);
      setNewOptionInput('');
    }
  };

  const handleRemoveOption = (index) => {
    setNewMetricOptions(prev => prev.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    if (!sections || sections.every(section => section.metrics.length === 0)) {
      setSnackbar({ open: true, message: 'Please add at least one metric before saving', severity: 'error' });
      return false;
    }
    for (let section of sections) {
      for (let metric of section.metrics) {
        if (!metric.name || !metric.type) {
          setSnackbar({ open: true, message: 'All metrics must have a name and type', severity: 'error' });
          return false;
        }
        if (metric.type === 'range' && (metric.min === undefined || metric.max === undefined || metric.min >= metric.max)) {
          setSnackbar({ open: true, message: 'Range metrics must have valid min and max values', severity: 'error' });
          return false;
        }
        if (metric.type === 'multipleChoice' && (!metric.options || metric.options.length < 2)) {
          setSnackbar({ open: true, message: 'Multiple Choice metrics must have at least two options', severity: 'error' });
          return false;
        }
      }
    }
    return true;
  };

  const handleSaveClick = () => {
    if (!year) {
      setSnackbar({ open: true, message: 'Please select a year before saving', severity: 'error' });
      return;
    }
    if (!validateForm()) {
      return;
    }
    setOpenConfirmDialog(true);
  };

  const handleConfirmSave = async () => {
    setOpenConfirmDialog(false);
    setLoading(true);
    try {
      console.log('Saving configuration:', { year, sections });
      const response = await axios.post(`${API_BASE_URL}/api/scouting-config/${year}`, { sections });
      console.log('Save response:', response.data);
      setSnackbar({ open: true, message: `Configuration for ${year} saved successfully`, severity: 'success' });
      await loadConfig(year);
    } catch (error) {
      console.error('Error saving configuration:', error);
      let errorMessage = 'An error occurred while saving the configuration';
      if (error.response) {
        console.error('Error response:', error.response.data);
        errorMessage = error.response.data.error || errorMessage;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const onDragEnd = (result) => {
    if (!result.destination || !sections) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    setSections(prevSections => {
      const newSections = [...prevSections];
      const [reorderedItem] = newSections[currentSection].metrics.splice(sourceIndex, 1);
      newSections[currentSection].metrics.splice(destIndex, 0, reorderedItem);
      return newSections;
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!sections) {
    return <Typography>Loading configuration...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Scouting Configuration
      </Typography>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Year</InputLabel>
        <Select
          value={year}
          label="Year"
          onChange={(e) => setYear(e.target.value)}
        >
          {availableYears.map((y) => (
            <MenuItem key={y} value={y.toString()}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tabs
        value={currentSection}
        onChange={(_, newValue) => setCurrentSection(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 2 }}
      >
        {sections.map((section, index) => (
          <Tab key={index} label={section.name} />
        ))}
      </Tabs>

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <TextField
          label="Metric Name"
          value={newMetricName}
          onChange={(e) => setNewMetricName(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={newMetricType}
            onChange={(e) => setNewMetricType(e.target.value)}
            label="Type"
          >
            {metricTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {newMetricType === 'range' && (
          <>
            <TextField
              label="Min"
              type="number"
              value={newMetricMin}
              onChange={(e) => setNewMetricMin(Number(e.target.value))}
              sx={{ width: 80 }}
            />
            <TextField
              label="Max"
              type="number"
              value={newMetricMax}
              onChange={(e) => setNewMetricMax(Number(e.target.value))}
              sx={{ width: 80 }}
            />
          </>
        )}
        <Button variant="contained" onClick={handleAddMetric}>
          Add
        </Button>
      </Box>

      {newMetricType === 'multipleChoice' && (
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Add Option"
            value={newOptionInput}
            onChange={(e) => setNewOptionInput(e.target.value)}
            sx={{ mr: 1 }}
          />
          <Button variant="outlined" onClick={handleAddOption}>
            Add Option
          </Button>
          <Box sx={{ mt: 1 }}>
            {newMetricOptions.map((option, index) => (
              <Chip
                key={index}
                label={option}
                onDelete={() => handleRemoveOption(index)}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
        </Box>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`section-${currentSection}`}>
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {sections[currentSection].metrics.map((metric, index) => (
                <Draggable key={index} draggableId={`metric-${index}`} index={index}>
                  {(provided) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DragIndicatorIcon sx={{ mr: 1 }} />
                      <ListItemText 
                        primary={metric.name} 
                        secondary={`Type: ${metricTypes.find(t => t.value === metric.type)?.label}${
                          metric.type === 'range' ? ` (${metric.min} - ${metric.max})` : ''
                        }${
                          metric.type === 'multipleChoice' ? ` (${metric.options.join(', ')})` : ''
                        }${
                          metric.type === 'attempted' ? ` (${ATTEMPTED_OPTIONS.join(', ')})` : ''
                        }`} 
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMetric(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSaveClick}
        >
          Save Configuration
        </Button>
        <Button 
          variant="outlined" 
          onClick={() => setShowPreview(!showPreview)}
        >
          {showPreview ? 'Hide Preview' : 'Show Preview'}
        </Button>
      </Box>

      {showPreview && <ScoutingFormPreview sections={sections} />}

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this configuration for {year}? This will overwrite any existing configuration for this year.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}