import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Checkbox, 
  FormControlLabel, 
  Radio, 
  RadioGroup, 
  FormControl, 
  FormLabel,
  Button,
  Slider
} from '@mui/material';

const ATTEMPTED_OPTIONS = ["Succeeded", "Attempted but Failed", "Didn't Attempt"];
const ATTEMPTED_COLORS = ["success", "error", "warning"];

const ScoutingFormPreview = ({ sections }) => {
  const [counters, setCounters] = useState({});
  const [rangeValues, setRangeValues] = useState({});
  const [multipleChoiceValues, setMultipleChoiceValues] = useState({});
  const [attemptedValues, setAttemptedValues] = useState({});

  const handleCounterChange = (metricName, type) => {
    setCounters(prev => ({
      ...prev,
      [metricName]: {
        ...prev[metricName],
        [type]: (prev[metricName]?.[type] || 0) + 1
      }
    }));
  };

  const handleRangeChange = (metricName, values) => {
    setRangeValues(prev => ({
      ...prev,
      [metricName]: values
    }));
  };

  const handleMultipleChoiceChange = (metricName, options) => {
    setMultipleChoiceValues(prev => ({
      ...prev,
      [metricName]: ((prev[metricName] || 0) + 1) % options.length
    }));
  };

  const handleAttemptedChange = (metricName) => {
    setAttemptedValues(prev => ({
      ...prev,
      [metricName]: ((prev[metricName] || 0) + 1) % ATTEMPTED_OPTIONS.length
    }));
  };

  const renderMetric = (metric) => {
    switch (metric.type) {
      case 'number':
        return <TextField label={metric.name} type="number" fullWidth margin="normal" />;
      case 'counter':
        const madeCount = counters[metric.name]?.made || 0;
        const missedCount = counters[metric.name]?.missed || 0;
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>{metric.name}</Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Button 
                  variant="contained" 
                  size="small" 
                  onClick={() => handleCounterChange(metric.name, 'made')}
                  sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                >
                  Made +
                </Button>
                <TextField 
                  value={madeCount} 
                  disabled 
                  size="small"
                  sx={{ width: '50px', margin: '0 10px', input: { textAlign: 'center' } }} 
                />
              </Box>
              <Box>
                <Button 
                  variant="contained" 
                  size="small" 
                  onClick={() => handleCounterChange(metric.name, 'missed')}
                  sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
                >
                  Missed +
                </Button>
                <TextField 
                  value={missedCount} 
                  disabled 
                  size="small"
                  sx={{ width: '50px', margin: '0 10px', input: { textAlign: 'center' } }} 
                />
              </Box>
            </Box>
          </Box>
        );
      case 'boolean':
        return <FormControlLabel control={<Checkbox />} label={metric.name} />;
      case 'text':
        return <TextField label={metric.name} fullWidth margin="normal" />;
      case 'ranking':
        return (
          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">{metric.name}</FormLabel>
            <RadioGroup row>
              {[1, 2, 3, 4, 5].map((value) => (
                <FormControlLabel key={value} value={value.toString()} control={<Radio />} label={value} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'range':
        return (
          <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
            <Typography gutterBottom>{metric.name}</Typography>
            <Slider
              value={rangeValues[metric.name] || [metric.min, metric.max]}
              onChange={(_, values) => handleRangeChange(metric.name, values)}
              valueLabelDisplay="auto"
              min={metric.min}
              max={metric.max}
              marks={[
                { value: metric.min, label: metric.min },
                { value: metric.max, label: metric.max }
              ]}
            />
            <Typography variant="caption">
              Selected range: {rangeValues[metric.name] ? `${rangeValues[metric.name][0]} - ${rangeValues[metric.name][1]}` : `${metric.min} - ${metric.max}`}
            </Typography>
          </Box>
        );
      case 'multipleChoice':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>{metric.name}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleMultipleChoiceChange(metric.name, metric.options)}
              fullWidth
            >
              {metric.options[multipleChoiceValues[metric.name] || 0]}
            </Button>
          </Box>
        );
      case 'attempted':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>{metric.name}</Typography>
            <Button
              variant="contained"
              color={ATTEMPTED_COLORS[attemptedValues[metric.name] || 0]}
              onClick={() => handleAttemptedChange(metric.name)}
              fullWidth
            >
              {ATTEMPTED_OPTIONS[attemptedValues[metric.name] || 0]}
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box mt={4}>
      <Typography variant="h5" gutterBottom>Form Preview</Typography>
      {sections.map((section, sectionIndex) => (
        <Box key={sectionIndex} mb={4}>
          <Typography variant="h6" gutterBottom>{section.name}</Typography>
          {section.metrics.map((metric, metricIndex) => (
            <Box key={metricIndex} mb={2}>
              {renderMetric(metric)}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ScoutingFormPreview;