import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Typography, 
  CircularProgress, 
  Alert, 
  Tabs, 
  Tab, 
  Box,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const styles = {
  redAlliance: {
    backgroundColor: '#8B0000',
  },
  blueAlliance: {
    backgroundColor: '#00008B',
  },
  redAllianceLight: {
    backgroundColor: '#a41818',
  },
  blueAllianceLight: {
    backgroundColor: '#110eb4',
  },
  winningScore: {
    fontWeight: 'bold',
    filter: 'brightness(300%)',
  },
  teamNumber: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
};

function EventDetails() {
  const [eventData, setEventData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const { eventKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoading(true);
        console.log(`Fetching details for event: ${eventKey}`);
        console.log(`API Base URL: ${API_BASE_URL}`);
        
        const eventUrl = `${API_BASE_URL}/api/events/${eventKey}`;
        const teamsUrl = `${API_BASE_URL}/api/events/${eventKey}/teams`;
        const matchesUrl = `${API_BASE_URL}/api/events/${eventKey}/matches`;
        
        console.log(`Fetching event details from: ${eventUrl}`);
        console.log(`Fetching teams from: ${teamsUrl}`);
        console.log(`Fetching matches from: ${matchesUrl}`);
        
        const [eventResponse, teamsResponse, matchesResponse] = await Promise.all([
          axios.get(eventUrl),
          axios.get(teamsUrl),
          axios.get(matchesUrl)
        ]);
        
        console.log('Event details response:', eventResponse.data);
        console.log('Teams response:', teamsResponse.data);
        console.log('Matches response:', matchesResponse.data);
        
        setEventData(eventResponse.data);
        setTeams(teamsResponse.data);
        setMatches(matchesResponse.data.sort((a, b) => a.time - b.time));
        setError(null);
      } catch (err) {
        console.error('Error fetching event details:', err);
        if (err.response) {
          console.error('Error status:', err.response.status);
          console.error('Error data:', err.response.data);
        }
        setError(`Failed to fetch event details. ${err.response ? err.response.data.error : err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventKey]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTeamNickname = (teamNumber) => {
    const team = teams.find(t => t.team_number === parseInt(teamNumber));
    return team ? team.nickname : 'Unknown Team';
  };

  const handleTeamClick = (teamKey, match) => {
    const teamData = teams.find(t => t.key === teamKey);
    navigate(`/scout/${eventKey}/${teamKey}/${match.key}`, { 
      state: { teamData, matchData: match }
    });
  };

  const renderTeamCell = (teamKey, allianceStyle, match) => (
    <TableCell key={teamKey} align="center" style={allianceStyle}>
      <Tooltip title={getTeamNickname(teamKey.slice(3))} arrow>
        <span 
          style={styles.teamNumber}
          onClick={() => handleTeamClick(teamKey, match)}
        >
          {teamKey.slice(3)}
        </span>
      </Tooltip>
    </TableCell>
  );

  const getAdditionalRPInfo = (match, alliance) => {
    if (!match.score_breakdown || !match.score_breakdown[alliance]) {
      console.log(`No score breakdown for ${alliance} alliance in match:`, match.key);
      return { count: 0, reason: 'RP information not available' };
    }
  
    const breakdown = match.score_breakdown[alliance];
    let additionalRP = 0;
    let reasons = [];
  
    const year = parseInt(match.event_key.substring(0, 4));
  
    const knownBonuses = {
      2024: ['melodyBonusAchieved', 'ensembleBonusAchieved'],
      2023: ['sustainabilityBonusAchieved', 'activationBonusAchieved'],
    };
  
    const bonusesToCheck = knownBonuses[year] || Object.keys(breakdown).filter(key => 
      key.endsWith('BonusAchieved') || key.includes('RankingPoint')
    );
  
    bonusesToCheck.forEach(bonus => {
      if (breakdown[bonus] === true) {
        additionalRP++;
        reasons.push(bonus.replace('BonusAchieved', '').replace('RankingPoint', '').replace(/([A-Z])/g, ' $1').trim());
      }
    });
  
    console.log(`Match ${match.key}, ${alliance} alliance:`, {
      year: year,
      breakdown: breakdown,
      additionalRP: additionalRP,
      reasons: reasons
    });
  
    return {
      count: additionalRP,
      reason: reasons.length > 0 ? reasons.join(' and ') : 'No additional RP'
    };
  };

  const renderMatchSchedule = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Match</TableCell>
              <TableCell align="center" colSpan={3} style={styles.redAlliance}>Red Alliance</TableCell>
              <TableCell align="center" style={styles.redAlliance}>Red Score</TableCell>
              <TableCell align="center" style={styles.blueAlliance}>Blue Score</TableCell>
              <TableCell align="center" colSpan={3} style={styles.blueAlliance}>Blue Alliance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map((match, index) => {
              const redWon = match.winning_alliance === 'red';
              const blueWon = match.winning_alliance === 'blue';
              const redRPInfo = getAdditionalRPInfo(match, 'red');
              const blueRPInfo = getAdditionalRPInfo(match, 'blue');
              const isAlternateRow = index % 2 !== 0;
              const redStyle = isAlternateRow ? styles.redAllianceLight : styles.redAlliance;
              const blueStyle = isAlternateRow ? styles.blueAllianceLight : styles.blueAlliance;
              
              return (
                <TableRow key={match.key}>
                  <TableCell component="th" scope="row">
                    {`${match.comp_level.toUpperCase()}${match.match_number}`}
                  </TableCell>
                  {match.alliances.red.team_keys.map(team => renderTeamCell(team, redStyle, match))}
                  <TableCell align="center" style={{
                    ...redStyle,
                    ...(redWon && styles.winningScore)
                  }}>
                    <Tooltip title={redRPInfo.reason} arrow>
                      <span>
                        {'*'.repeat(redRPInfo.count)}
                        {match.alliances.red.score >= 0 ? match.alliances.red.score : 'TBD'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center" style={{
                    ...blueStyle,
                    ...(blueWon && styles.winningScore)
                  }}>
                    <Tooltip title={blueRPInfo.reason} arrow>
                      <span>
                        {'*'.repeat(blueRPInfo.count)}
                        {match.alliances.blue.score >= 0 ? match.alliances.blue.score : 'TBD'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  {match.alliances.blue.team_keys.map(team => renderTeamCell(team, blueStyle, match))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTeamsList = () => (
    <List>
      {teams.map(team => (
        <ListItem key={team.key}>
          <ListItemText
            primary={`${team.team_number} - ${team.nickname}`}
            secondary={
              <>
                {team.school_name && <Typography component="span" variant="body2" color="textPrimary">
                  {team.school_name}
                </Typography>}
                <br />
                {`${team.city}, ${team.state_prov}, ${team.country}`}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!eventData) return <Alert severity="warning">No event data available</Alert>;

  return (
    <Box>
      <Typography variant="h4">{eventData.name}</Typography>
      <Typography variant="subtitle1">
        {new Date(eventData.start_date).toLocaleDateString()} - {new Date(eventData.end_date).toLocaleDateString()}
      </Typography>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="event details tabs">
            <Tab label="Match Schedule" value="1" />
            <Tab label="Teams" value="2" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          {renderMatchSchedule()}
        </TabPanel>
        <TabPanel value="2">
          {renderTeamsList()}
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default EventDetails;